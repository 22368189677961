<template>
  <div class="employee-index" v-cloak>
    <transition
      name="fade"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <div key="1" v-if="loader" class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else key="1" class="white-row">
        <!-- // Filter Area -->
        <div class="general-filters">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <div class="row top-gen-heading">
                  <h1 class="global-style-heading">
                    Employees
                    <a
                      @click.prevent="refreshEmployeeTable"
                      href=""
                      class="refreshData"
                      ><i class="fas fa-redo-alt"></i
                    ></a>
                  </h1>
                </div>
              </div>
              <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-9">
                        <form @submit.prevent="">
                          <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                              <div class="row">
                                <div class="form-group">
                                  <label for="gender" class="control-label"
                                    >&nbsp;</label
                                  >
                                  <div class="input-group">
                                    <input
                                      @keyup="getEmpData(1, 'search')"
                                      v-model="formData.search"
                                      type="text"
                                      class="form-control"
                                      placeholder="Search...."
                                    />
                                    <div class="input-group-append">
                                      <button class="btn btn-secondary" type="button">
                                        <i class="fa fa-search"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                              <div class="row">
                                <div class="form-group">
                                  <label for="gender" class="control-label"
                                    >&nbsp;</label
                                  >
                                  <Select2
                                    @change="getEmpData(1)"
                                    v-model="formData.role_id"
                                    :options="roles_options"
                                    :settings="{
                                      placeholder: 'Select Role',
                                      allowClear: true
                                    }"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                              <div class="row">
                                <div class="form-group">
                                  <label for="gender" class="control-label"
                                    >&nbsp;</label
                                  >
                                  <Select2
                                    @change="getEmpData(1)"
                                    v-model="formData.brand_id"
                                    :options="brands_options"
                                    :settings="{
                                      placeholder: 'Select Brand',
                                      allowClear: true
                                    }"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="col-sm-3">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                              <div class="form-actions">
                                <router-link
                                  type="submit"
                                  id="save-form"
                                  class="btn btn-primary"
                                  :to="{
                                    name: 'employees.create'
                                  }"
                                  tag="button"
                                >
                                  <i class="fas fa-plus-circle"></i> Add New Employee
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="horizontal-row">
          <hr />
        </div>
        <div class="emp-listing">
          <transition
            name="fade"
            mode="out-in"
            @beforeLeave="beforeLeave"
            @enter="enter"
            @afterEnter="afterEnter"
          >
            <div key="1" v-if="emp_loader" class="white-row loader-route">
              <div class="lds-sub-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
            <div v-else class="listing-wrapper">
              <div
                class="row"
                v-if="
                  typeof emp_list.data !== 'undefined' &&
                    Object.keys(emp_list.data).length
                "
              >
                <div
                  v-for="(emp, emp_index) in emp_list.data"
                  :key="emp_index"
                  class="col-xl-4 col-lg-4 col-md-6 col-sm-6"
                >
                  <div class="wrap-box">
                    <div class="header-top">
                      <dropdown>
                        <template slot="icon"
                          ><i class="fas fa-ellipsis-v"></i
                        ></template>
                        <template slot="body">
                          <ul class="list-group">
                            <li class="list-group-item">
                              <router-link
                                :to="{
                                  path: '/employees/edit/' + emp.id
                                }"
                                tag="a"
                              >
                                Edit
                              </router-link>
                            </li>
                            <!-- <li class="list-group-item">
                                <a href="#">Delete</a>
                              </li> -->
                          </ul>
                        </template>
                      </dropdown>
                    </div>
                    <div class="box">
                      <div class="emp-details">
                        <avatar class="mr-3" :username="emp.name"></avatar>
                        <p class="user-title eclipse-para">{{ emp.name }}</p>
                        <p class="user-email eclipse-para">
                          {{ emp.email }}
                        </p>
                      </div>
                      <div class="footer-box">
                        <div class="footer-box">
                          <p class="usr-role">User Role:</p>
                          <p
                            v-for="(rol, rol_index) in emp.role"
                            :key="rol_index"
                            class="usr-role-name text-right"
                          >
                            {{ rol.charAt(0).toUpperCase() + rol.slice(1) }}
                          </p>
                        </div>
                        <div
                          class="footer-box"
                          v-if="
                            typeof emp.brand !== 'undefined' &&
                              Object.keys(emp.brand).length
                          "
                        >
                          <p class="usr-role">User Brand:</p>
                          <p
                            v-for="(brand, brand_index) in emp.brand"
                            :key="brand_index"
                            class="usr-role-name text-right"
                          >
                            {{
                              brand.name.charAt(0).toUpperCase() +
                                brand.name.slice(1)
                            }}
                          </p>
                        </div>
                        <div v-else class="footer-box">
                          <p class="usr-role">User Brand:</p>
                          <p class="usr-role-name text-right">N/A</p>
                        </div>
                        <div class="footer-box">
                          <p class="usr-role">Login: </p>
                          <p
                            class="usr-role-name text-right"
                          >
                            {{ emp.login }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row">
                <div class="col-sm-12">
                  <no-record-found></no-record-found>
                </div>
              </div>
            </div>
          </transition>
          <div class="row">
            <div class="col-sm-12">
              <pagination
                class="pagination justify-content-center"
                :data="emp_list"
                :limit="1"
                @pagination-change-page="getEmpData"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Select2 from "v-select2-component";
import Avatar from "vue-avatar";
import Dropdown from "bp-vuejs-dropdown";
import norecordfound from "../../../components/no-record-found";

export default {
  components: {
    Select2,
    Avatar,
    Dropdown,
    "no-record-found": norecordfound
  },
  data() {
    return {
      loader: true,
      formData: {
        search: "",
        brand_id: null,
        role_id: null
      },
      brands_options: null,
      roles_options: null,
      emp_loader: true,
      emp_list: {}
    };
  },
  mounted() {
    document.title =
      "PNC Work Bench - " + this.$router.app._route.meta.pageTitle;
    this.getBrandData();
    this.getRolesData();
    this.getEmpData();
    this.loader = false;
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  methods: {
    refreshEmployeeTable() {
      this.getBrandData();
      this.getRolesData();
      this.getEmpData();
    },
    async getBrandData() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "filters/get-project-brand"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.brands_options = response.data.data;
        }
      });
    },
    async getRolesData() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "filters/get-all-roles"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.roles_options = response.data.data;
        }
      });
    },
    async getEmpData(page = 1, action = null) {
      const $this = this;
      if (action == "search") {
        if ($this.formData.search.length < 2 && $this.formData.search != "") {
          return true;
        }
      }

      $this.emp_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "employees?" +
          "&page=" +
          page +
          "&search=" +
          $this.formData.search +
          "&role_id=" +
          $this.formData.role_id +
          "&brand_id=" +
          $this.formData.brand_id
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.emp_list = response.data;
        }
        $this.emp_loader = false;
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>
<style lang="scss" scoped>
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.employee-index {
  .general-filters {
    .form-group {
      width: 95%;
      .input-group {
        input {
          border-radius: 3px !important;
          background: #f9f9f9;
          letter-spacing: 0.28px;
          font-size: 14px;
          padding: 3px 15px;
          color: #364460;
          border: none;
          font-weight: 200;
          height: auto;
        }
        button {
          background: #f9f9f9;
          color: #3b4c54;
          border: none;
          padding: 4px 8px;
        }
      }
    }
    .form-actions {
      width: 100%;
      button {
        width: 100%;
        margin-left: 0;
        padding: 5px 0;
      }
    }
    span.select2.select2-container.select2-container--default {
      width: 100% !important;
    }
  }
  .horizontal-row {
    padding: 30px 0 30px;
    hr {
      margin: 0;
    }
  }
  .wrap-box {
    background: #faf9f7;
    border-radius: 3px;
    padding: 0 20px;
    margin-bottom: 30px;
    position: relative;
    .header-top {
      position: absolute;
      right: 26px;
      z-index: 100;
      top: 19px;
      .bp-dropdown__btn {
        border: none;
      }
      .bp-dropdown__body {
        padding: 0;
        background-color: #fff;
        box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
        z-index: 9999;
        border-radius: 4px;
        border: none;
        margin-top: 10px;
        .list-group {
          .list-group-item {
            border: none;
            position: relative;
            display: block;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            padding: 10px 20px 10px 7px;
            text-align: left;
            a {
              color: #212529;
            }
          }
        }
      }
    }
    .emp-details {
      position: relative;
      padding: 20px 0 20px 44px;
      border-bottom: 1px solid #ebebeb;
      p {
        margin: 0;
      }
      .mr-3 {
        width: 32px !important;
        height: 32px !important;
        position: absolute;
        top: 26px;
        left: 0;
        span {
          letter-spacing: 0.24px;
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .footer-box {
      display: table;
      width: 100%;
      padding: 12px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      &:first-child {
        border-top: none;
        padding-top: 2px;
      }
      p {
        margin: 0;
        display: table-cell;
        &.usr-role {
          letter-spacing: 0.24px;
          color: #918a82;
        }
        &.usr-role-name {
          letter-spacing: 0.24px;
          color: #323f54;
          display: block;
        }
      }
    }
  }
  .justify-content-center {
    margin-bottom: 0;
  }
}

// Extra large devices (lg and less)
@media screen and (max-width: 1199px) {
}
// Extra large devices (md and less)
@media screen and (max-width: 991px) {
  body .top-gen-heading {
    padding-bottom: 15px;
  }
}

// Extra large devices (sm and up)
@media screen and (max-width: 767px) {
  body .top-gen-heading {
    padding-bottom: 15px;
  }
}

// Extra large devices (xs and up)
@media screen and (max-width: 575px) {
  .employee-index .general-filters .form-group[data-v-32e6c314] {
    width: 100%;
  }
  body .top-gen-heading {
    padding-bottom: 15px;
  }
}

// Extra large devices (xl, 1200px and up)
@media (min-width: 1200) {
}
</style>
