<template>
  <div class="viewDashBoard" v-cloak>
    <div class="container">
      <div
        v-if="
          typeof getActiveUserData.user !== 'undefined' &&
            Object.keys(getActiveUserData.user).length
        "
      >
        <adminvue-emp-vue
          v-if="(getActiveUserData.userRoles == 'admin') || (getActiveUserData.userRoles == 'subadmin')"
        ></adminvue-emp-vue>
        <sales-team-lead-emp-vue
          v-else-if="(getActiveUserData.userRoles == 'salesteamlead') || (getActiveUserData.userRoles == 'salesmanager')"
        ></sales-team-lead-emp-vue>
        <no-component-found v-else></no-component-found>
      </div>
      <div v-else class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noComponentFound from "../../components/noComponentFound";
import adminvue from "./components/emp_admin";
import salesteamleadtablevue from "./components/emp_salesteamlead";
// import salespersonevue from './components/salesperson_dashboard';
// import clientvue from './components/client_dashboard';
// import default_vue from './components/default_dashboard';

export default {
  components: {
    "adminvue-emp-vue": adminvue,
    "sales-team-lead-emp-vue": salesteamleadtablevue,
    "no-component-found": noComponentFound
    // "sales-person-dashboard-vue" : salespersonevue,
    // "client-dashboard-vue" : clientvue,
    // "default-dashboard-vue" : default_vue,
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {};
  },
  mounted() {
    document.title =
      "PNC Work Bench - " + this.$router.app._route.meta.pageTitle;
  },
  methods: {}
};
</script>
